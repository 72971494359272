<template>
    <div class="no-modal">
      <div class="container-busqueda">
        <div class="cuadro-busqueda col-md-4">
          <form class="input-group" @keyup="getCompany()">
            <input type="search" class="form-control" v-model="search" placeholder="Buscar..." />
          </form>
        </div>
        <div class="dropdown m-1">
                  <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Filtrar por Status
                  </button>
                  <ul class="dropdown-menu">
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="filterActivos()">Activos</a></li>
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="filterBajas()">Bajas</a></li>
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="filterTodos()">Todos</a></li>
                  </ul>
        </div>
        <div class="dropdown m-1">
                  <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Filtrar por Tipo
                  </button>
                  <ul class="dropdown-menu">
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="filterEgresados()">Profesionista</a></li>
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="filterBecarios()">Becario</a></li>
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="filterTodos()">Todos</a></li>
                  </ul>
        </div>
        <div class="dropdown m-1">
                  <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Exportar a Excel
                  </button>
                  <ul class="dropdown-menu">
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="downloadExcelBecarios()">Becarios</a></li>
                      <li><a class="dropdown-item" style="cursor: pointer;" v-on:click="downloadExcelProfesionistas()">Profesionistas</a></li>
                  </ul>
        </div>
      </div>
      <div class="container-table p-3 mt-2 flex" style="flex-direction: column">
        <table class="table align-middle table-responsive table-hover content-top mt-5">
          <thead class="placeholder-glow">
            <tr align="center" style="font-family: Poppins">
              <th scope="col">#</th>
              <th scope="col">Nombre</th>
              <th scope="col">Posición</th>
              <th scope="col">Unidad / División</th>
              <th scope="col">Departamento</th>
              <th scope="col">Ingreso</th>
              <th scope="col">Fecha de Alta</th>
              <th scope="col">Fecha de Baja</th>
              <th scope="col">Documentos Colaborador</th>
              <th scope="col">Documentos Empresa</th>
              <th scope="col">Tipo</th>
              <th scope="col">Consultar</th>
            </tr>
          </thead>
          <tbody v-if="!response.loading">
            <tr>
              <td>
                <div class="d-flex justify-content-center">
                  <div class="spinner-border text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else v-for="(item, i) in dataPage" :key="i + 1" style="font-family: 'Poppins'; text-align: center">
            <tr align="center">
              <td>{{ item.index }}</td>
              <td>{{ item.postulante.name }} {{ item.postulante.lastname }} {{ item.postulante.secondlastname }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.salary }}</td>
              <td>
                <div v-show="!item.dateHired"></div>
                <div v-show="item.dateHired">
                  {{ moment.utc(item.dateHired).format("DD/MM/YYYY").split('T')[0] }}
                
                </div>
              </td>
              <td>
                <div v-show="!item.dateSecondPeriod"></div>
                <div v-show="item.dateSecondPeriod">
                  {{ moment.utc(item.dateSecondPeriod).format("DD/MM/YYYY").split('T')[0] }}
                </div>
              </td>
              <td v-if="item.vacantes.type === 'Becario'">
                  <div v-if="allDocumentsPresentBecario(item)">     
                      <div>100%</div>
                  </div>
                  <div v-else>
                      <div>{{ calculateProgressBecario(item) }}%</div>
                  </div>
              </td>
              <td v-else>
                  <div v-if="allDocumentsPresentEgresado(item)">     
                      <div>100%</div>
                  </div>
                  <div v-else>
                      <div>{{ calculateProgressEgresado(item) }}%</div>
                  </div>
              </td>
              <td v-if="item.vacantes.type === 'Becario'">
                <div>
                  <div v-if="item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3">33%</div>
                  <div v-else-if="item.document_convenio2 && !item.document_convenio1 && !item.document_convenio3">33%</div>
                  <div v-else-if="item.document_convenio3 && !item.document_convenio1 && !item.document_convenio2">33%</div>

                  <div v-else-if="item.document_convenio1 && item.document_convenio2 && !item.document_convenio3">66%</div>
                  <div v-else-if="item.document_convenio1 && item.document_convenio3 && !item.document_convenio2">66%</div>
                  <div v-else-if="item.document_convenio2 && item.document_convenio3 && !item.document_convenio1">66%</div>

                  <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio3">100%</div>
                  <div v-else>0%</div>
                </div>
              </td>
              <td v-else>
                <div>
                  <div v-if="item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3 && !item.document_convenio4">25%</div>
                  <div v-else-if="item.document_convenio2 && !item.document_convenio1 && !item.document_convenio3 && !item.document_convenio4">25%</div>
                  <div v-else-if="item.document_convenio3 && !item.document_convenio1 && !item.document_convenio2 && !item.document_convenio4">25%</div>
                  <div v-else-if="item.document_convenio4 && !item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3">25%</div>

                  <div v-else-if="item.document_convenio1 && item.document_convenio2 && !item.document_convenio3 && !item.document_convenio4">50%</div>
                  <div v-else-if="item.document_convenio1 && item.document_convenio3 && !item.document_convenio2 && !item.document_convenio4">50%</div>
                  <div v-else-if="item.document_convenio1 && item.document_convenio4 && !item.document_convenio2 && !item.document_convenio3">50%</div>
                  <div v-else-if="item.document_convenio2 && item.document_convenio3 && !item.document_convenio1 && !item.document_convenio4">50%</div>
                  <div v-else-if="item.document_convenio2 && item.document_convenio4 && !item.document_convenio1 && !item.document_convenio3">50%</div>
                  <div v-else-if="item.document_convenio3 && item.document_convenio4 && !item.document_convenio1 && !item.document_convenio2">50%</div>

                  <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio3 && !item.document_convenio4">75%</div>
                  <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio4 && !item.document_convenio3">75%</div>
                  <div v-else-if="item.document_convenio1 && item.document_convenio3 && item.document_convenio4 && !item.document_convenio2">75%</div>
                  <div v-else-if="item.document_convenio2 && item.document_convenio3 && item.document_convenio4 && !item.document_convenio1">75%</div>

                  <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio3 && item.document_convenio4">100%</div>
                  <div v-else>0%</div>
                </div>
              </td>
              <td v-if="item.vacantes.type === 'Becario'">Becario</td>
              <td v-else>Profesionista</td>
              <td>
                <div class="btn-margin-main">
                  <button class="btn-style" title="Ver Contratados" v-on:click="linkAdministration(item.vacantes._id, item.postulante._id, item.vacantes.type)">
                    <box-icon name="group" color="#03337A"></box-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Paginador -->
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()">
              <a class="page-link" href="#">Anterior</a>
            </li>
            <li v-for="(page, i) in totalPagesNumber" :key="i + 1" v-on:click="getDataPages(page)" v-bind:class="isActive(page)" class="page-item">
              <a class="page-link" href="#">{{ page }}</a>
            </li>
            <li class="page-item" v-on:click="getNextPage()">
              <a class="page-link" href="#">Siguiente</a>
            </li>
          </ul>
          <ul class="pagination justify-content-center">
                <li class="page-item">
                    Página <b>{{ this.actual }}</b> de <b>{{ totalPagesNumber }}</b> - Total de <b>{{ this.listVacantes.length }}</b>
                </li>
          </ul>
        </nav>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import * as ClientService from "../../services/client"
  import { CompanyService } from "@/services";
  import moment from "moment";
  import exportXlsExcel from "export-from-json";
  moment.locale("es");
  
  export default {
    data: () => ({
      moment: moment,
      search: "",
      searchInput: "",
      vigencia: new Date(moment(new Date()).subtract('3', 'months').format('YYYY-MM-DD hh:mm:ss')),
      listVacantes: [],
      listVacantesClient: [],
      listVacantesTemp: [],
      response: {
        loading: false
      },
      table: null,
      elementSearch: "",
      elementPage: 7,
      dataPage: [],
      totalPagesNumber: 0,
      actual: 1,
      countElements: 0,
    }),
    mounted() {
      this.getCompany();
    },
    methods: {
      async getCompany() {
        try {
          this.response.loading = true
          const user = JSON.parse(localStorage.getItem("user") || null);
          await CompanyService.getContratadosClients({ search: this.search }).then((resp) => {
            let { data } = resp.data;
            console.log(resp.data.data)
            this.listVacantes = data
            this.listVacantesTemp = [...data]; 
            this.totalPages(this.listVacantes.length)
            this.listVacantes.length > 0 ? this.getDataPages(1) : this.response.loading = true;
          }).catch((e) => {
            this.$swal({
              position: "top-end",
              toast: true,
              icon: "error",
              title: e.response.data.message,
              showConfirmButton: false,
              timer: 5000,
            });
          });
        } catch (error) {
          this.response.loading = false
        }
      },
      //TODO: Metodo Ordenar Vacantes fecha
      filterVacantesTFecha(vacantes) {
        vacantes.sort((a, b) => {
          if (a.createdAt == b.createdAt) {
            return 0;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 1;
        });
        return vacantes;
      },
      //TODO: Filtrado O Busqueda Por Vacantes
      filter(vacante) {
        if (this.searchInput != "") {
          return vacante.titulo.toLowerCase().match(this.searchInput.toLocaleLowerCase()) || vacante.ubicacion.toLowerCase().match(this.searchInput.toLocaleLowerCase()) || vacante.modalidad.toLowerCase().match(this.searchInput.toLocaleLowerCase());
        }
        else {
          return vacante;
        }
      },
      linkAdministration(vacante, postulante, type) {
        type === 'Becario' ? this.$router.push(`/information/clients/vacante/${vacante}/becario/${postulante}`) : this.$router.push(`/information/vacante/${vacante}/egresado/${postulante}`)
      },
      linkVacante(id) {
        this.$router.push(`/vacante/${id}/postulantes`);
      },
      async totalPages(data) {
        console.log("Cantidad total de elementos:", data); 
        this.totalPagesNumber = Math.ceil(data / this.elementPage);
      },
      getDataPages(numberPage) {
        this.actual = numberPage;
        const start = (numberPage - 1) * this.elementPage;
        const end = Math.min(start + this.elementPage, this.listVacantes.length); 
        const startIndex = (numberPage - 1) * this.elementPage; 
        this.dataPage = this.listVacantes.slice(start, end).map((item, index) => ({
          ...item,
          index: startIndex + index + 1
        }));
      },
      getPreviousPage() {
        if (this.actual > 1) {
          this.actual--;
        }
        this.getDataPages(this.actual);
      },
      getNextPage() {
        if (this.actual < this.totalPagesNumber) {
          this.actual++;
        }
        this.getDataPages(this.actual);
      },
      isActive(numberPage) {
        return numberPage == this.actual ? "active" : "";
      },
      filterActivos() {
      let vacantesFilter = this.listVacantesTemp.filter(vac => {
          return vac.dateHired && !vac.dateSecondPeriod;
      });
          this.listVacantes = vacantesFilter;
          this.totalPages(vacantesFilter.length);
          this.getDataPages(1);
      },
      filterBajas() {
        let vacantesFilter = this.listVacantesTemp.filter(vac => {
            return vac.dateSecondPeriod != null;
        });
        this.listVacantes = vacantesFilter;
        this.totalPages(vacantesFilter.length);
        this.getDataPages(1);
      },
      filterTodos() {
          this.listVacantes = this.listVacantesTemp; 
          this.totalPages(this.listVacantes.length); 
          this.getDataPages(1); 
      },
      filterBecarios() {
          let vacantesFilter = this.listVacantesTemp.filter(vac => {
              return vac.vacantes.type === 'Becario';
          });
          this.listVacantes = vacantesFilter;
          this.totalPages(vacantesFilter.length);
          this.getDataPages(1);
      },
      filterEgresados() {
          let vacantesFilter = this.listVacantesTemp.filter(vac => {
              return vac.vacantes.type === 'Egresado';
          });
          this.listVacantes = vacantesFilter;
          this.totalPages(vacantesFilter.length);
          this.getDataPages(1);
      },
      allDocumentsPresentBecario(item) {
          return item.postulante.filecv &&
            item.postulante?.filecomprobantes &&
            item.document_acta &&
            item.document_ine &&
            item.document_comprobante &&
            item.document_curp &&
            item.document_rfc &&
            item.document_seguro &&
            item.document_personales &&
            item.document_accidentes &&
            item.document_historialAcademico &&
            item.document_horario &&
            item.document_ComprobanteInscripcion;
      },
      calculateProgressBecario(item) {
        const totalDocuments = 13; 
        let presentDocuments = 0;
          if (item.postulante.filecv) presentDocuments++;
          if (item.postulante?.filecomprobantes) presentDocuments++;
          if (item.document_acta) presentDocuments++;
          if (item.document_ine) presentDocuments++;
          if (item.document_comprobante) presentDocuments++;
          if (item.document_curp) presentDocuments++;
          if (item.document_rfc) presentDocuments++;
          if (item.document_seguro) presentDocuments++;
          if (item.document_personales) presentDocuments++;
          if (item.document_accidentes) presentDocuments++;
          if (item.document_historialAcademico) presentDocuments++;
          if (item.document_horario) presentDocuments++;
          if (item.document_ComprobanteInscripcion) presentDocuments++;
        return Math.ceil((presentDocuments / totalDocuments) * 100);
      },
      allDocumentsPresentEgresado(item) {
        return item.postulante.filecv &&
          item.postulante.filecomprobantes &&
          item.document_acta &&
          item.document_ine &&
          item.document_comprobante &&
          item.document_curp &&
          item.document_rfc &&
          item.document_seguro &&
          item.document_personales &&
          item.document_INFONAVIT; 
      },
      calculateProgressEgresado(item) {
        const totalDocuments = 10;
        let presentDocuments = 0;
        if (item.postulante.filecv) presentDocuments++;
        if (item.postulante.filecomprobantes) presentDocuments++;
        if (item.document_acta) presentDocuments++;
        if (item.document_ine) presentDocuments++;
        if (item.document_comprobante) presentDocuments++;
        if (item.document_curp) presentDocuments++;
        if (item.document_rfc) presentDocuments++;
        if (item.document_seguro) presentDocuments++;
        if (item.document_personales) presentDocuments++;
        if (item.document_INFONAVIT) presentDocuments++; 
        return Math.ceil((presentDocuments / totalDocuments) * 100);
      },
      async downloadExcelBecarios() {
        try {
          const resp = await ClientService.exportClientExcel({ search: this.search });
          const data = resp.data.data;
          console.log(data);
          const becarios = data.filter(item => item.vacantes.type === 'Becario');
          if (becarios.length === 0) {
            alert("No hay datos de becarios para descargar.");
            return;
          }
          becarios.sort((a, b) => {
            if (a.dateHired && b.dateHired) {
              return new Date(b.dateHired) - new Date(a.dateHired);
            } else if (a.dateHired) {
              return -1;
            } else if (b.dateHired) {
              return 1;
            } else {
              return 0;
            }
          });
          const getAntiguedad = (dateHired, dateSecondPeriod) => {
            const startDate = moment(dateHired).startOf('day');
            let endDate = dateSecondPeriod ? moment(dateSecondPeriod).startOf('day') : moment().startOf('day'); 
            if (dateSecondPeriod && endDate < startDate) {
              return 0;
            }
            if (!dateSecondPeriod) {
              endDate = endDate.subtract(1, 'day'); 
            }
            const tiempoDiferencia = endDate.diff(startDate, 'days');
            return Math.max(tiempoDiferencia, 0); 
          }
          const calcularEdad = (fechaNacimiento) => {
            const nacimiento = new Date(fechaNacimiento);
            const hoy = new Date();
            let edad = hoy.getFullYear() - nacimiento.getFullYear();
            const mes = hoy.getMonth() - nacimiento.getMonth();
            if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
              edad--;
            }
            return edad;
          }
          const generateExcelBecarios = (userData, fileName) => {
            let nuevoJSON = [];
            userData.forEach(item => {
              let dateFirstPeriod = item.dateFirstPeriod ? moment.utc(item.dateFirstPeriod).format("DD/MM/YYYY") : "";
              let dateHired = item.dateHired ? moment.utc(item.dateHired).format("DD/MM/YYYY") : "";
              let dateSecondPeriod = item.dateSecondPeriod ? moment.utc(item.dateSecondPeriod).format("DD/MM/YYYY") : "";
              let secondEndDate = item.secondEndDate ? moment.utc(item.secondEndDate).format("DD/MM/YYYY") : "";
              let idiomas = "";
              if (Array.isArray(item.postulante.idiomas)) {
                item.postulante.idiomas.forEach(idioma => {
                  if (typeof idioma.idioma === 'string' && idioma.idioma.trim() !== "") {
                    idiomas += idioma.idioma + "/ ";
                  }
                });
              }
              let nivelesIdioma = "";
              if (Array.isArray(item.postulante.idiomas)) {
                item.postulante.idiomas.forEach(idioma => {
                  if (typeof idioma.nivel === 'string' && idioma.nivel.trim() !== "") {
                    nivelesIdioma += idioma.nivel + "/ ";
                  }
                });
              }
              let edad = calcularEdad(item.postulante.date);
              let direccionCompleta = [
                item.postulante.country,
                item.postulante.estadoRepublica,
                item.postulante.municipaly,
                item.postulante.delegation
              ].filter(Boolean).join(', ');
              let antiguedadPracticas = getAntiguedad(item.dateHired, item.dateSecondPeriod) + " días";
              let nuevoObjeto = {
                IdAdministración: item.idAdministration,
                Nombre: item.postulante.name,
                Apellido_Paterno: item.postulante.lastname,
                Apellido_Materno: item.postulante.secondlastname,
                Unidad_División: item.company,
                Ubicacion: item.ubicationCompany,
                Departamento: item.department,
                Posición: item.position,
                Nombre_Centro_Costos: item.nameCostCenter,
                Centro_Costos: item.costCenter,
                Beca_Mensual: item.salary,
                Mentor: item.mentor,
                Correo_mentor: item.emailMentor,
                Status_becario: item.statusActually,
                Fecha_Ingreso: dateHired,
                Termino_1erConvenio: dateFirstPeriod,
                Termino_2doConvenio: secondEndDate,
                Fecha_Baja: dateSecondPeriod,
                Antiguedad_Practicas: antiguedadPracticas,
                Fecha_Nacimiento: item.postulante.date,
                Edad: edad,
                Genero: item.postulante.gender,
                CURP: item.postulante.curp,
                RFC_Registro_Federal_de_Contribuyentes: item.rfc,
                Correo_Eléctronico: item.postulante.email,
                Correo_Corporativo: item.emailCorporative,
                Teléfono_Celular: item.postulante.phone,
                Dirección_Completa: direccionCompleta,
                Banco: item.bank,
                Núm_Cuenta_Bancaria: item.bankAccount,
                Escuela: item.postulante.school,
                Plantel: item.postulante.plantel,
                Matricula: item.schoolEnrollment,
                Semestre: item.schoolSemester,
                Promedio: item.postulante.promedio,
                Años_Estudiando: item.postulante.yearActual,
                Duración_años_carrera: item.postulante.yearTotal,
                Idioma: idiomas,
                Nivel_Idioma: nivelesIdioma,
                Aseguradora: item.insuranceCarrier,
                Núm_Poliza: item.policyNumber,
                Titulación: item.titulacion,
                Nombre_Contacto_Emergencia: item.nameContact,
                Parentesco_Contacto: item.relationship,
                Teléfono_Contacto: item.numberContact,
                Nombre_Contacto_Emergencia_2: item.nameContactTwo,
                Parentesco_Contacto_2: item.relationshipTwo,
                Telefono_Contacto_2: item.numberContactTwo,   
              };
              nuevoJSON.push(nuevoObjeto);
            });
            let exportType = exportXlsExcel.types.xls;
            exportXlsExcel({ data: nuevoJSON, fileName, exportType });
          };
          generateExcelBecarios(becarios, 'Export_Inf_Becarios');
        } catch (error) {
          console.error("Error al descargar y exportar datos de becarios a Excel:", error);
        }
      },
      async downloadExcelProfesionistas() {
        try {
          const resp = await ClientService.exportClientExcel({ search: this.search });
          const data = resp.data.data;
          console.log(data);
          const profesionistas = data.filter(item => item.vacantes.type !== 'Becario');
          if (profesionistas.length === 0) {
            alert("No hay datos de profesionistas para descargar.");
            return;
          }
          profesionistas.sort((a, b) => {
            if (a.dateFirstPeriod && b.dateFirstPeriod) {
              return new Date(b.dateFirstPeriod) - new Date(a.dateFirstPeriod);
            } else if (a.dateFirstPeriod) {
              return -1; // A tiene fecha, B no
            } else if (b.dateFirstPeriod) {
              return 1; // B tiene fecha, A no
            } else {
              return 0; // Ambos sin fecha
            }
          });
          const calcularEdad = (fechaNacimiento) => {
            const nacimiento = new Date(fechaNacimiento);
            const hoy = new Date();
            let edad = hoy.getFullYear() - nacimiento.getFullYear();
            const mes = hoy.getMonth() - nacimiento.getMonth();
            if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
              edad--;
            }
            return edad;
          }
          const getAntiguedad = (dateHired, dateSecondPeriod) => {
            const startDate = moment(dateHired).startOf('day');
            let endDate = dateSecondPeriod ? moment(dateSecondPeriod).startOf('day') : moment().startOf('day'); 
            if (dateSecondPeriod && endDate < startDate) {
              return 0;
            }
            if (!dateSecondPeriod) {
              endDate = endDate.subtract(1, 'day'); 
            }
            const tiempoDiferencia = endDate.diff(startDate, 'days');
            return Math.max(tiempoDiferencia, 0); 
          }
          const generateExcelProfesionistas = (userData, fileName) => {
            let nuevoJSON = [];
            userData.forEach(item => {
              let dateFirstPeriod = item.dateFirstPeriod ? moment.utc(item.dateFirstPeriod).format("DD/MM/YYYY") : "";
              let dateHired = item.dateHired ? moment.utc(item.dateHired).format("DD/MM/YYYY") : "";
              let dateSecondPeriod = item.dateSecondPeriod ? moment.utc(item.dateSecondPeriod).format("DD/MM/YYYY") : "";
              let idiomas = "";
              if (Array.isArray(item.postulante.idiomas)) {
                item.postulante.idiomas.forEach(idioma => {
                  if (typeof idioma.idioma === 'string' && idioma.idioma.trim() !== "") {
                    idiomas += idioma.idioma + "/ ";
                  }
                });
              }
              let nivelesIdioma = "";
              if (Array.isArray(item.postulante.idiomas)) {
                item.postulante.idiomas.forEach(idioma => {
                  if (typeof idioma.nivel === 'string' && idioma.nivel.trim() !== "") {
                    nivelesIdioma += idioma.nivel + "/ ";
                  }
                });
              } 
              let edad = calcularEdad(item.postulante.date);
              let direccionCompleta = [
                item.postulante.delegation,
                item.postulante.municipaly,
                item.postulante.estadoRepublica,
                item.postulante.country,
              ].filter(Boolean).join(', ');
              let antiguedadPracticas = getAntiguedad(item.dateHired, item.dateSecondPeriod) + " días";
              let nuevoObjeto = {
                IdAdministración: item.idAdministration,
                Nombre: item.postulante.name,
                Apellido_Paterno: item.postulante.lastname,
                Apellido_Materno: item.postulante.secondlastname,
                Unidad_División: item.company,
                Departamento: item.department,
                Posición: item.position,
                Nivel: item.level,
                Sueldo_Mensual: item.salary,
                Status: item.statusActually,
                Fecha_Ingreso: dateFirstPeriod,
                Termino_Periodo_Prueba: dateHired,
                Fecha_Baja: dateSecondPeriod,
                Antiguedad: antiguedadPracticas,
                Fecha_Nacimiento: item.postulante.date,
                Edad: edad,
                Genero: item.postulante.gender,
                CURP: item.postulante.curp,
                RFC_Registro_Federal_de_Contribuyentes: item.rfc,
                Número_Seguridad_Social: item.nss,
                Crédito_Infonavit: item.infonavitCredit,
                Correo_Eléctronico: item.postulante.email,
                Correo_Corporativo: item.emailCorporative,
                Teléfono_Celular: item.postulante.phone,
                Dirección: direccionCompleta,
                Banco: item.bank,
                Núm_Cuenta_Bancaria: item.bankAccount,
                Escuela_Licenciatura: item.postulante.schoolLic,
                Carrera_Licenciatura: item.postulante.careerLic,
                Inicio_Licenciatura: item.postulante.startDateLic,
                Fin_Licenciatura: item.postulante.endDateLic,
                Idioma: idiomas,
                Nivel_Idioma: nivelesIdioma,
                Nombre_Contacto_Emergencia: item.nameContact,
                Parentesco_Contacto: item.relationship,
                Teléfono_Contacto: item.numberContact,
                Nombre_Contacto_Emergencia_2: item.nameContactTwo,
                Parentesco_Contacto_2: item.relationshipTwo,
                Telefono_Contacto_2: item.numberContactTwo,
              };
              nuevoJSON.push(nuevoObjeto);
            });
            let exportType = exportXlsExcel.types.xls;
            exportXlsExcel({ data: nuevoJSON, fileName, exportType });
          };
          generateExcelProfesionistas(profesionistas, 'Export_Inf_Profesionistas');
        } catch (error) {
          console.error("Error al descargar y exportar datos de profesionistas a Excel:", error);
        }
      },
  
    },
  };
  </script>
  
  <style land="scss" scoped>
  @import "../../styles/talento.scss";
  </style>
  